<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app class="pt-4 not-printable" color="primary" mini-variant fixed right v-if="$store.state.user != null">
      <!-- <v-navigation-drawer v-model="drawer" app class="pt-4 not-printable" color="primary" mini-variant fixed right> -->
      <!-- <v-avatar v-for="(item, i) in items" :key="i" class="d-block text-center mx-auto mb-9"> <v-icon v-text="item.icon" color="white"></v-icon></v-avatar>
      <v-avatar @click="logout">
        <v-icon left color="white"> mdi-account-circle </v-icon>
      </v-avatar> -->
      <v-list>
        <v-subheader></v-subheader>
        <v-list-item-group v-model="selectedItem" color="accent">
          <v-list-item v-for="(item, i) in items" :key="i" style="padding: 10px 15px" @click="page(item)">
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="white">{{ item.icon }}</v-icon>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-icon left color="white" @click="logoutPage()"> mdi-logout </v-icon>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="primary" v-if="$store.state.user != null" class="not-printable">
      <!-- <v-app-bar app color="primary"> -->
      <div class="d-flex align-center" style="width: 100%">
        <div style="text-align: right">
          <v-avatar color="secondary" size="50">
            <v-img :src="require('./assets/logo.png')" class="my-3" contain />
          </v-avatar>
        </div>
        <v-spacer></v-spacer>
        <h5 style="color: aliceblue">برنامج التسجيل وشؤون الطلبة</h5>
        <v-spacer></v-spacer>
        <h5 style="color: aliceblue">جامعة التراث</h5>
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    drawer: true,
    selectedItem: 0,
    login: false,
    items: [
      { title: "/", text: "الرئيسية", icon: "mdi-home" },
      {
        title: "/students",
        text: "الطلبة",
        icon: "mdi-account-search",
      },
      { title: "/order", text: "الاوامر الادارية", icon: "mdi-text-box-search" },
      { title: "/reports", text: "التقارير", icon: "mdi-file-chart" },
    ],
  }),
  created() {
    this.$store
      .dispatch("getUserDate")
      .then(() => {
        if (this.$store.state.user) {
          this.login = true;
          this.$router.push("/").catch(() => {});
          this.initialization();
        } else {
          this.$router.push("/login").catch(() => {});
          this.login = false;
        }
      })
      .catch(() => {});
  },
  methods: {
    initialization() {
      let self = this;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlRegistration}/acceptedTypes`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/sections`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/yearStudies`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/allStudentStatus`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/orderTitles`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/passTypes`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/allCertificateStatus`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/studyCategories`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/studySubCategories`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/provinces`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/imageTypes`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
        ])
        .then(
          self.$http.spread((acceptedTypes, sections, yearStudies, studentStatus, orderTitles, passTypes, allCertificateStatus, studyCategories, studySubCategories, provinces, imageTypes) => {
            console.log(passTypes.data);
            console.log(allCertificateStatus.data);
            self.$store.state.sections = sections.data;
            self.$store.state.imageTypes = imageTypes.data;
            self.$store.state.provinces = provinces.data;
            self.$store.state.studentStatus = studentStatus.data;
            self.$store.state.acceptedTypes = acceptedTypes.data;
            self.$store.state.yearStudies = yearStudies.data;
            self.$store.state.orderTitles = orderTitles.data;
            self.$store.state.passTypes = passTypes.data;
            self.$store.state.allCertificateStatus = allCertificateStatus.data;
            self.$store.state.studyCategories = studyCategories.data;
            self.$store.state.studySubCategories = studySubCategories.data;

            self.overlay = false;
          })
        )
        .catch((e) => {
          console.log(e);
          if (e.response.status === 401) {
            localStorage.removeItem("register");
            location.reload();
          } else {
            self.overlay = false;
            this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
          }
        });
    },
    page(item) {
      this.$router.push(item.title).catch(() => {});
    },
    logoutPage() {
      localStorage.removeItem("register");
      location.reload();
    },
  },
};
</script>
<style>
@import url("./font.css");
</style>
